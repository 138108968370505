@font-face {
  font-family: "MARKPRO";
  src: url("./fonts/MARKPRO.OTF") format("truetype");
}

@font-face {
  font-family: "MARKPROBLACK";
  src: url("./fonts/MARKPROBLACK.OTF") format("truetype");
}

@font-face {
  font-family: "MARKPROBOLD";
  src: url("./fonts/MARKPROBOLD.OTF") format("truetype");
}

@font-face {
  font-family: "MARKPROBOOK";
  src: url("./fonts/MARKPROBOOK.OTF") format("truetype");
}

@font-face {
  font-family: "MARKPROEXTRALIGHT";
  src: url("./fonts/MARKPROEXTRALIGHT.OTF") format("truetype");
}

@font-face {
  font-family: "MARKPROHEAVY";
  src: url("./fonts/MARKPROHEAVY.OTF") format("truetype");
}

@font-face {
  font-family: "MARKPROLIGHT";
  src: url("./fonts/MARKPROLIGHT.OTF") format("truetype");
}

@font-face {
  font-family: "MARKPROMEDIUM";
  src: url("./fonts/MARKPROMEDIUM.OTF") format("truetype");
}

.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container::after {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("./images/chevron.png");
  background-size: 100%;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.canvasjs-chart-credit {
  display: none;
}
